import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
`;

export const Head = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns:
    repeat(5, minmax(100px, auto)) 2fr repeat(3, minmax(160px, auto))
    32px;
  /* grid-template-columns:
    repeat(5, minmax(100px, auto)) 2fr
    32px; */
  margin-bottom: 16px;
  padding: 0 16px;
`;

export const ButtonAll = styled.button.attrs({
  type: "button",
})`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px ${({ theme }) => theme.colors.lightDarkBlue} solid;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.lightDarkBlue : "transparent"};
  transition: 300ms ease;
  margin-right: 12px;

  :hover {
    transform: scale(1.1);
  }
`;

export const Label = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  font-size: 12px;
  /* padding-left: 16px; */
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;
