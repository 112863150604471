import styled from "styled-components";

export const StatusBar = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusItem = styled.li`
  position: relative;
  background: ${props => props.active ? '#3BBCA3' : '#fff'};
  padding: 10px 15px;
  border-radius: 4px;

  & + li {
    margin-left: 120px;
  }

  strong {
    color:  ${props => props.active ? '#fff' : '#333'};
  }

  small {
    position: absolute;
    top: -20px;
    left: -30px;
    white-space: nowrap;
    font-weight: bold;
    font-size: 14px;
  }
`;

