import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ClassificationDeleteActions from "../../ducks/classification/delete";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* classificationDelete(action) {
  try {
    const { id } = action;
    const url = `classification/${id}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.delete, url, makeOptions(authData));
    yield put(ClassificationDeleteActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Tipo Deletado!", "classification.delete", language);
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ClassificationDeleteActions.failure(err.response.data.error.message)
    );
  }
}
