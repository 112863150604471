import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ResponsibleDeleteActions from "../../ducks/responsible/delete";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* responsibleDelete(action) {
  try {
    const { id } = action;
    const url = `responsible/${id}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.delete, url, makeOptions(authData));
    console.log(data);
    yield put(ResponsibleDeleteActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Responsável Deletado!", "responsible.delete", language);
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ResponsibleDeleteActions.failure(err.response.data.error.message)
    );
  }
}
