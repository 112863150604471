import React from "react";
import { useDispatch } from "react-redux";
import AuthActions from "~/store/ducks/auth";

import { Container, Name, Logout } from "./styles";
import { LogoutCircleR } from "@styled-icons/remix-line";

function User() {
  const dispatch = useDispatch();

  const userLogout = () => {
    dispatch(AuthActions.reset());
  };

  return (
    <Container>
      <Name>Usuário</Name>
      <Logout onClick={userLogout}>
        <LogoutCircleR size={20} />
      </Logout>
    </Container>
  );
}

export default User;
