import { put } from "redux-saga/effects";
import { notify } from "~/services/notification";
import languageActions from "../ducks/language";

export function* fetchLanguage(action) {
  try {
    const data = yield action.data
      ? action.data
      : navigator.language.split(/[-_]/)[0];
    yield put(languageActions.success(data));
  } catch (err) {
    notify("error");
    yield put(languageActions.failure("Erro"));
  }
}
