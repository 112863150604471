import styled from "styled-components";

import goodsale from "~/assets/icons/goodsale.svg";
import pending from "~/assets/icons/pending.svg";
import question from "~/assets/icons/question.svg";
import negotiation from "~/assets/icons/negotiation.svg";
import returnn from "~/assets/icons/return.svg";
import rework from "~/assets/icons/rework.svg";
import shelflifeextension from "~/assets/icons/shelflifeextension.svg";
import temporaryblocked from "~/assets/icons/temporaryblocked.svg";
import writeoff from "~/assets/icons/writeoff.svg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${(props) => props.themes.bg};
  background-image: ${(props) => `url(${props.themes.img})`};
  background-size: ${(props) => props.themes.size};
  cursor: ${(props) => props.themes.cursor};
`;

export const theme = {
  goodsale: {
    bg: "#34DB93 no-repeat center",
    img: goodsale,
  },
  pending: {
    bg: "#3498DB no-repeat center",
    img: pending,
  },
  negotiation: {
    bg: "#8234DB no-repeat center",
    img: negotiation,
  },
  question: {
    bg: "#A4A9AC no-repeat center",
    img: question,
  },
  return: {
    bg: "#344ADB no-repeat center",
    img: returnn,
  },
  rework: {
    bg: "#DBBF34 no-repeat center",
    img: rework,
  },
  shelflifeextension: {
    bg: "#8B0737 no-repeat center",
    img: shelflifeextension,
  },
  temporaryblocked: {
    bg: "#DB3450 no-repeat center",
    img: temporaryblocked,
  },
  writeoff: {
    bg: "#DB8D34 no-repeat center",
    img: writeoff,
  },
};

Container.defaultProps = {
  themes: {
    bg: "#ccc no-repeat center",
    size: "60%",
    cursor: "default",
  },
};
