import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import stdsStatusActions from "~/store/ducks/std/list";
import qualitiesActions from "~/store/ducks/quality/list";
import productBranchActionActions from "~/store/ducks/productBranchAction";

import Switch from "@material-ui/core/Switch";

import { Form } from "@unform/web";
import { SelectTest, Textarea } from "~/components/Form";
import Modal from "~/components/Modal";
import { Button } from "~/styles/components";
import Text from "~/components/Text";

import * as S from "./styles";

function ModalNPI({ open, handleClose, listChecked, onActionSubmit }) {
  const dispatch = useDispatch();
  const formAction = useRef();
  const close = () => {
    handleClose();
  };

  const [checked, setChecked] = React.useState(false);

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const { data: qualityList } = useSelector((state) => state.qualities);
  const { data: std } = useSelector(
    (state) => state.stdsStatus
  );
  const { data: language } = useSelector((state) => state.language);


  const [stdList, setStdList] = useState([]);

  useEffect(() => {
    dispatch(stdsStatusActions.request());
    dispatch(qualitiesActions.request());
  }, []);

  useEffect(() => {
    function handleListLanguage() {
      let list = [];

      if(std) {
        list = std.data.map(listItem => {
          const id = listItem.id;
          let name = listItem.name;
          switch (language) {
            case 'en':
              name = listItem.name;
              break;

            case 'pt':
              name = listItem.name_pt;
              break;

            case 'es':
              name = listItem.name_es;
              break;

            default:
              break;
          }

          return {
            name,
            id,
          }
        })
      }

      setStdList(list);
    }

    handleListLanguage();
  }, [std, language]);

  async function handleActionSubmit(values) {
    try {
      formAction.current.setErrors({});
      const schema = Yup.object().shape({
        quality: Yup.object().typeError("Selecione uma análise de qualidade"),
        std_status: Yup.object().typeError("Selecione um STD Status"),
        status_details: Yup.string(),
        observation: Yup.string(),
      });
      await schema.validate(values, {
        abortEarly: false,
      });


      await listChecked.map(async item => {
        const data = {
          product_branch_plant_id: item.id,
          quality_id: values.quality.id,
          std_status_id: values.std_status.id,
          status_details: values.status_details,
          observation: values.observation,

          sla_id: 1,
          name: 'null',
        };
        await dispatch(productBranchActionActions.request(data));
      });

      onActionSubmit();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAction.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal open={open} close={close}>
      <S.Container>
        <S.Title>
          <Text id="modal.npi" dm="NPIs Selecionados" />
        </S.Title>
        <S.ListNpi>
          {listChecked.map((item) => (
            <S.Item key={item.id}>
              <Text id="inventory.batch" dm="Lote" />: {item.batch}
            </S.Item>
          ))}
        </S.ListNpi>
        <Form
          ref={formAction}
          onSubmit={handleActionSubmit}>
          <S.Row templatecolumns="2">
            <SelectTest
              label={<Text id="inventory.std" dm="STD Status" />}
              name="std_status"
              placeholder="Selecione um STD Status"
              options={stdList}
            />
            <SelectTest
              label={
                <Text id="inventory.quality" dm="Análise de Qualidade" />
              }
              name="quality"
              placeholder="Análise de Qualidade"
              options={qualityList && qualityList.data}
            />
          </S.Row>
          <S.Row templatecolumns="2">
            <Textarea
              label={<Text id="inventory.status_details" dm="Status de detalhes" />}
              id="status_details"
              name="status_details"
              cols="30"
              rows="10"
            />
            <Textarea
              label={
                <Text id="inventory.observation" dm="Observação do Planejador" />
              }
              id="observation"
              name="observation"
              cols="30"
              rows="10"
            />
          </S.Row>
          <S.WrapBtns>
            <Button type="submit" btStyle="primary">
              <Text id="salvar" dm="Salvar" />
            </Button>
          </S.WrapBtns>
        </Form>
      </S.Container>
    </Modal>
  );
}

export default ModalNPI;
