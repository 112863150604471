import React, { useState, useEffect } from "react";
import moment from "moment";
import { Container, Header, Head, ButtonAll, Label } from "./styles";
import ItemNPI from "../ItemNPI";
import Paginator from "~/components/Paginator";
import Text from "~/components/Text";

function ListNPIs({ verifyCheck, data, pagination, onPageChange }) {

  useEffect(() => {
    if(data) {
      data.map(item => {
        const general_stock = item.last_inventory_stocks && item.last_inventory_stocks[0] ? item.last_inventory_stocks[0].general_quantity : 0;
        const real_value = item.product.financial_imports && item.product.financial_imports[0] ? item.product.financial_imports[0].real_value : 0;
        const dolar_value = item.product.financial_imports && item.product.financial_imports[0] ? item.product.financial_imports[0].dolar_value : 0;

        const real_total = real_value * general_stock;
        const dolar_total = dolar_value * general_stock;

        item.real_total = real_total;
        item.dolar_total = dolar_total;
      })
    }
  }, []);


  return (
    <Container>
      <Header>
        <ButtonAll />
        <Head>
          <Label>
            <Text id="inventory.data" dm="Data" />
          </Label>
          <Label>
            <Text id="inventory.plant" dm="Planta" />
          </Label>
          <Label>
            <Text id="inventory.legacy" dm="Empresa" />
          </Label>
          <Label>
            <Text id="inventory.batch" dm="Lote" />
          </Label>
          <Label>
            <Text id="inventory.dcode" dm="Código" />
          </Label>
          <Label>
            <Text id="inventory.description" dm="Descrição" />
          </Label>
          <Label>
            <Text id="inventory.storageloca" dm="Local de Armazenagem" />
          </Label>
          <Label>
            <Text id="inventory.generalstock" dm="Estoque Geral" />
          </Label>
          <Label>
            <Text id="inventory.total" dm="Total Dolar" />
          </Label>
        </Head>
      </Header>
      {data &&
        data.map((item) => (
          <ItemNPI
            key={item.id}
            id={item.id}
            verifycheck={verifyCheck}
            date="Jul 2020"
            plant={item.plant.name}
            legacy="Dupont"
            batch={item.batch}
            dcode={item.product.code}
            description={item.product.name || item.product.description}
            start={moment.utc(item.createdAt).format("DD/MM/YYYY")}
            finish="2020-07-11"
            newdate="2020-07-21"
            stl={
              item.last_inventory_stocks &&
              item.last_inventory_stocks[0] &&
              item.last_inventory_stocks[0].storage_location ||
              '-'
            }
            stock={
              item.last_inventory_stocks &&
              item.last_inventory_stocks[0] &&
              item.last_inventory_stocks[0].general_quantity ||
              '-'
            }
            usd={
              item.last_inventory_stocks &&
              item.last_inventory_stocks[0] &&
              item.last_inventory_stocks[0].total_usd ||
              item.dolar_total ||
              '-'
            }
            status={
              item.product_branch_actions &&
              item.product_branch_actions[0] &&
              item.product_branch_actions[0].std_status.name
            }
          />
        ))}
      <Paginator pagination={pagination} onPageChange={onPageChange} />
    </Container>
  );
}

export default ListNPIs;
