import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ResponsibleEditActions from "../../ducks/responsible/edit";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* responsibleEdit(action) {
  try {
    const { id, data: patch } = action;
    const url = `responsible/${id}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.patch, url, patch, makeOptions(authData));

    yield put(ResponsibleEditActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Responsável alterado!", "responsible.edit", language);
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(ResponsibleEditActions.failure(err.response.data.error.message));
  }
}
