import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);

  svg {
    margin-right: 8px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark};
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-right: 16px;

    :last-child {
      margin-right: 0;
    }
  }
`;

export const ButtonFilter = styled.button.attrs({
  type: "button",
})`
  color: ${({ theme }) => theme.colors.dark};
`;

export const Content = styled.section`
  width: 100%;
  padding: 0 32px;
`;

export const ButtonModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 9;

  transition: all ease 0.3s;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 60px);
  svg {
    margin-right: 20px;
  }
  cursor: pointer;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
  button {
    font-size: 20px;
    font-family: ${({ theme }) => theme.fonts.titleBold};
    color: ${({ theme }) => theme.colors.white};
    padding: 20px 30px;
    transition: all ease 0.3s;
    background: ${({ theme }) => theme.colors.lightDarkBlue};
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
    :hover {
      transform: scale(0.95);
    }

    :first-child {
      background: ${({ theme }) => theme.colors.blueDark};
    }
  }
`;
