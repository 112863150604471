import React, { useRef, useState } from "react";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import ImportInventoryActions from "~/store/ducks/importInventory";
import { useDispatch, useSelector } from "react-redux";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";
import XLSX from "xlsx";

import Header from "~/components/Header";
import { Input } from "~/components/Form";
import Loading from "~/components/Loading";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../styles";
import { ButtonMini, Button } from "~/styles/components";

function Responsible() {
  const formLogin = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data, loading: loadingImportInventory } = useSelector(
    (state) => state.importInventory
  );

  const getWorkbookFromFile = async (excelFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary", cellDates: true });
        resolve(workbook);
      };
      reader.readAsBinaryString(excelFile);
    });
  };

  const handleImport = async (file) => {
    const name = file[0].name.split(".");
    const index = name.length - 1;
    if (
      name[index] === "xlsx" ||
      name[index] === "xls" ||
      name[index] === "XLSX" ||
      name[index] === "XLS"
    ) {
      setLoading(true);
      setFileData([]);
      const workbook = await getWorkbookFromFile(file[0]);
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const firstColumn = first_worksheet.A1 ? first_worksheet.A1.v : null;
      const newFileData = await XLSX.utils.sheet_to_json(first_worksheet, {
        header: 0,
      });
      setLoading(false);
      await sendImport(newFileData);
    } else {
      setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
      setLoading(false);
    }
  };

  const sendImport = async (newFileData) => {
    try {
      console.log("####Começou", data);
      dispatch(ImportInventoryActions.request(newFileData));
      console.log("####Terminou", data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="import.title" dm="Responsável" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="import.upload" dm="Subir arquivo" />
        </SubTitle>
        {loadingImportInventory || loading ? (
          <Loading />
        ) : (
          <Form ref={formLogin}>
            <WrapForm>
              <Row templatecolumns="1">
                <Input
                  label={<Text id="import.arquivo" dm="Arquivo" />}
                  type="file"
                  name="import"
                  onChange={(event) =>
                    event.target.files[0] && handleImport(event.target.files)
                  }
                />
              </Row>
            </WrapForm>
          </Form>
        )}
      </Content>
    </Container>
  );
}

export default Responsible;
