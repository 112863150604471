import styled from "styled-components";

export const Container = styled.div`
  background-color: #774ca2;
  border-radius: 6px;
  padding: 16px;
  width: 100%;
  max-width: 320px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.h6`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
`;

export const Percent = styled(Name)`
  font-size: 20px;
`;

export const BgBar = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 6px;
  border-radius: 3px;
  margin: 16px 0;
`;

export const Bar = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: ${({ value }) => (value > 100 ? "100%" : `${value}%`)};
  height: 6px;
  border-radius: 3px;
`;

export const Value = styled.span`
  font-family: "Gilroy-Bold";
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
`;
