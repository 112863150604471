import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ClassificationEditActions from "../../ducks/classification/edit";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* classificationEdit(action) {
  try {
    const { id, data: patch } = action;
    const url = `classification/${id}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.patch, url, patch, makeOptions(authData));
    yield put(ClassificationEditActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Tipo alterado!", "classification.edit", language);
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ClassificationEditActions.failure(err.response.data.error.message)
    );
  }
}
