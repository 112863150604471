import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { PackageIcon, LeftArrowAlt } from "@styled-icons/boxicons-regular";
import ProductBranchPlantShowActions from "~/store/ducks/productBranchPlant/show";
import ProductBranchPlantEditActions from "~/store/ducks/productBranchPlant/edit";
import initiativesActions from "~/store/ducks/initiative/list";
import stdsStatusActions from "~/store/ducks/std/list";
import qualitiesActions from "~/store/ducks/quality/list";
import productBranchActionActions from "~/store/ducks/productBranchAction";

import { useParams } from "react-router";

import {
  Container,
  Filters,
  Title,
  Content,
  ProductHeader,
  ProductName,
  Dcode,
  Tags,
  Tag,
  WrapMonth,
  BoxMonth,
  TitMonth,
  BarMonth,
  ValueMonth,
  SubTitle,
  WrapPrevious,
  NewPlanner,
  WrapBtns,
  BoxChart,
  Row,
} from "./styles";

import { ButtonMini, Badge, Button } from "~/styles/components";
import Header from "~/components/Header";
import Loading from "~/components/Loading";
import ChartLine from "~/components/ChartLine";
import Accordion from "~/components/Accordion";
import { Form } from "@unform/web";
import { Textarea, SelectTest } from "~/components/Form";
import Text from "~/components/Text";

function Details() {
  const formInitiative = useRef();
  const formAction = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const { data, loading } = useSelector(
    (state) => state.productBranchPlantShow
  );
  const { data: qualityList } = useSelector((state) => state.qualities);
  const { data: std } = useSelector(
    (state) => state.stdsStatus
  );
  const { data: initiativeList } = useSelector((state) => state.iniciatives);
  const { data: language } = useSelector((state) => state.language);

  const [item, setItem] = useState(null);
  const [action, setAction] = useState(null);

  const [accorVisible, setAccorVisible] = useState(3);
  const [totalAccord, setTotalAccord] = useState();

  const [stdList, setStdList] = useState([]);

  const fectSegments = useCallback(() => {
    dispatch(ProductBranchPlantShowActions.request(id));
    dispatch(initiativesActions.request());
    dispatch(stdsStatusActions.request());
    dispatch(qualitiesActions.request());
  }, [dispatch]);

  useEffect(() => {
    fectSegments();
  }, [id]);

  useEffect(() => {
    if (data && data[0] && data.length === 1) {
      setItem(data[0]);
      setAction(data[0].product_branch_actions[0]);
      setTotalAccord(data[0].product_branch_actions.length);
    }
  }, [data]);

  useEffect(() => {
    function handleListLanguage() {
      let list = [];

      if(std) {
        list = std.data.map(listItem => {
          const id = listItem.id;
          let name = listItem.name;
          switch (language) {
            case 'en':
              name = listItem.name;
              break;

            case 'pt':
              name = listItem.name_pt;
              break;

            case 'es':
              name = listItem.name_es;
              break;

            default:
              break;
          }

          return {
            name,
            id,
          }
        })
      }

      setStdList(list);
    }

    handleListLanguage();
  }, [std, language]);

  async function handleActionSubmit(values) {
    if (!values.std_status) {
      values.std_status = action ? action.std_status : null;
    }
    if (!values.quality) {
      values.quality = action ? action.quality : null;
    }
    try {
      formAction.current.setErrors({});
      const schema = Yup.object().shape({
        quality: Yup.object().typeError("Selecione uma análise de qualidade"),
        std_status: Yup.object().typeError("Selecione um STD Status"),
        status_details: Yup.string(),
        observation: Yup.string(),
      });
      await schema.validate(values, {
        abortEarly: false,
      });

      const data = {
        product_branch_plant_id: item.id,
        quality_id: values.quality.id,
        std_status_id: values.std_status.id,
        status_details: values.status_details,
        observation: values.observation,

        sla_id: 1,
        name: 'null',
      };

      dispatch(productBranchActionActions.request(data));
      fectSegments();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAction.current.setErrors(validationErrors);
      }
    }
  }

  async function handleInitiativeSubmit(values) {
    try {
      formInitiative.current.setErrors({});
      const schema = Yup.object().shape({
        initiative: Yup.object().typeError("Selecione uma iniciativa"),
      });
      await schema.validate(values, {
        abortEarly: false,
      });

      const data = {
        initiative_id: values.initiative.id,
      };

      const id = item.id

      dispatch(ProductBranchPlantEditActions.request(id, data));
      fectSegments();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formInitiative.current.setErrors(validationErrors);
      }
    }
  }

  const loadMore = () => {
    setAccorVisible(accorVisible + 4);
  };

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <PackageIcon size={24} />
          <Text id="inventory.title" dm="Estoque" />
        </Title>
        <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
          <LeftArrowAlt size={16} />
          <Text id="voltar" dm="Voltar" />
        </ButtonMini>
      </Filters>
      <Content>
        {loading ? (
          <Loading />
        ) : (
          <>
            <ProductHeader>
              <ProductName>
                <Dcode>{item && item.product.code}</Dcode>
                {item && (item.product.name || item.product.description)}
              </ProductName>
              <Badge bgColor="primary">Pending Analisys</Badge>
            </ProductHeader>
            <Tags>
              <Tag>
                <span>SLA:</span> NPI
              </Tag>
              <Tag>
                <span>
                  <Text id="inventory.unt" dm="Unidade" />:
                </span>{" "}
                PCA
              </Tag>
              <Tag>
                <span>
                  <Text id="inventory.plant" dm="Planta" />:
                </span>{" "}
                {item && item.plant.name}
              </Tag>
              <Tag>
                <span>
                  <Text id="inventory.legacy" dm="Empresa" />:
                </span>{" "}
                WR59
              </Tag>
              <Tag>
                <span>
                  <Text id="inventory.batch" dm="Lote" />:
                </span>{" "}
                {item && item.batch}
              </Tag>
              <Tag>
                <span>
                  <Text id="inventory.classi" dm="Tipo" />:
                </span>{" "}
                Packaging
              </Tag>
            </Tags>
            <WrapMonth>
              <BoxMonth>
                <TitMonth>
                  <Text id="inventory.mesant" dm="Mês Anterior" /> - JUN 2020{" "}
                  <span>30%</span>
                </TitMonth>
                <BarMonth color="previous" percent="20" height="6" />
                <ValueMonth color="previous">$ 8.101,92 </ValueMonth>
              </BoxMonth>
              <BoxMonth>
                <TitMonth>
                  <Text id="inventory.mesatu" dm="Mês Atual" /> - JUL 2020{" "}
                  <span>30%</span>
                </TitMonth>
                <BarMonth percent="20" height="6" />
                <ValueMonth>$ 8.101,92 </ValueMonth>
              </BoxMonth>
            </WrapMonth>

            <BoxChart>
              <ChartLine />
            </BoxChart>
            <WrapPrevious>
              <SubTitle>
                <Text id="inventory.acoesant" dm="Ações anteriores" />
              </SubTitle>
              {item &&
              item.product_branch_actions &&
              item.product_branch_actions[0] ? (
                item.product_branch_actions
                  .slice(0, accorVisible)
                  .map((actionItem) => (
                    <Accordion data={actionItem} key={actionItem.id} />
                  ))
              ) : (
                <p>Lista Vazia</p>
              )}
              {accorVisible < totalAccord && (
                <ButtonMini btStyle="secondary" onClick={loadMore}>
                  <Text id="carregarmais" dm="Carregar mais …" />
                </ButtonMini>
              )}
            </WrapPrevious>
            <WrapPrevious>
              <SubTitle>
                <Text id="inventory.novainiciativa" dm="Nova Iniciativa" />
              </SubTitle>

              <NewPlanner>
                <Form
                  initialData={{
                    initiative: item && item ? item.initiative : null,
                  }}
                  ref={formInitiative}
                  onSubmit={handleInitiativeSubmit}>
                  <Row templatecolumns="1">
                    <SelectTest
                      label={<Text id="inventory.initiative" dm="Iniciativa" />}
                      name="initiative"
                      placeholder="Selecione uma Iniciativa"
                      options={initiativeList && initiativeList.data}
                    />
                  </Row>
                  <WrapBtns>
                    <Button
                      type="reset"
                      btStyle="secondary"
                      onClick={() => history.goBack()}>
                      <Text id="cancelar" dm="Cancelar" />
                    </Button>
                    <Button type="submit" btStyle="primary">
                      <Text id="salvar" dm="Salvar" />
                    </Button>
                  </WrapBtns>
                </Form>
              </NewPlanner>
            </WrapPrevious>
            <WrapPrevious>
              <SubTitle>
                <Text id="inventory.novaacao" dm="Nova Ação" />
              </SubTitle>

              <NewPlanner>
                <Form
                  initialData={{
                    std_status: item && action ? action.std_status : null,
                    quality: item && action ? action.quality : null,
                    status_details: item && action ? action.status_details : '',
                    observation: item && action ? action.observation : '',
                  }}
                  ref={formAction}
                  onSubmit={handleActionSubmit}>
                  <Row templatecolumns="2">
                    <SelectTest
                      label={<Text id="inventory.std" dm="STD Status" />}
                      name="std_status"
                      placeholder="Selecione um STD Status"
                      options={std.data}
                      getOptionLabel={option =>
                        language === 'en'?
                        option.name :
                        option[`name_${language}`]
                      }
                    />
                    <SelectTest
                      label={
                        <Text id="inventory.quality" dm="Análise de Qualidade" />
                      }
                      name="quality"
                      placeholder="Análise de Qualidade"
                      options={qualityList && qualityList.data}
                    />
                  </Row>
                  <Row templatecolumns="2">
                    <Textarea
                      label={<Text id="inventory.status_details" dm="Status de detalhes" />}
                      id="status_details"
                      name="status_details"
                      cols="30"
                      rows="10"
                    />
                    <Textarea
                      label={
                        <Text id="inventory.observation" dm="Observação do Planejador" />
                      }
                      id="observation"
                      name="observation"
                      cols="30"
                      rows="10"
                    />
                  </Row>
                  <WrapBtns>
                    <Button
                      type="reset"
                      btStyle="secondary"
                      onClick={() => history.goBack()}>
                      <Text id="cancelar" dm="Cancelar" />
                    </Button>
                    <Button type="submit" btStyle="primary">
                      <Text id="salvar" dm="Salvar" />
                    </Button>
                  </WrapBtns>
                </Form>
              </NewPlanner>
            </WrapPrevious>
          </>
        )}
      </Content>
    </Container>
  );
}

export default Details;
