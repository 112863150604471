import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { useDispatch, useSelector } from "react-redux";
import ResponsibleAShowctions from "~/store/ducks/responsible/show";
import ResponsibleEditActions from "~/store/ducks/responsible/edit";
import ResponsibleDeleteActions from "~/store/ducks/responsible/delete";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";
import { useParams } from "react-router";

import Header from "~/components/Header";
import Loading from "~/components/Loading";
import { Input } from "~/components/Form";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../styles";
import { ButtonMini, Button } from "~/styles/components";

function Responsible() {
  const formLogin = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const { data: dataRespo, loading: loadingRespo } = useSelector(
    (state) => state.responsibleShow
  );

  useEffect(() => {
    dispatch(ResponsibleAShowctions.request(id));
  }, [id]);

  async function handleSubmit(data) {
    try {
      formLogin.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required("Digite o responsável"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      await dispatch(ResponsibleEditActions.request(id, data));
      history.push("/config/responsible");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  async function handleDelete() {
    try {
      console.log(id);
      await dispatch(ResponsibleDeleteActions.request(id));
      history.push("/config/responsible");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="respon.title" dm="Responsável" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="informacoes" dm="Informações" />
        </SubTitle>
        {loadingRespo ? (
          <Loading />
        ) : (
          <Form ref={formLogin} onSubmit={handleSubmit}>
            <WrapForm>
              <Row templatecolumns="1">
                <Input
                  label={<Text id="respon.title" dm="Responsável" />}
                  name="name"
                  defaultValue={dataRespo.data.name}
                />
              </Row>
            </WrapForm>
            <WrapBtns>
              <Button type="reset" onClick={handleDelete} btStyle="delete">
                <Text id="deletar" dm="Deletar" />
              </Button>
              <Button type="reset" btStyle="secondary">
                <Text id="cancelar" dm="Cancelar" />
              </Button>
              <Button type="submit" btStyle="primary">
                <Text id="salvar" dm="Salvar" />
              </Button>
            </WrapBtns>
          </Form>
        )}
      </Content>
    </Container>
  );
}

export default Responsible;
