import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import QualityActions from "../../ducks/quality/new";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* createQuality(action) {
  try {
    const { post, history } = action;
    const url = `quality`;
    const authData = yield select(getAuthData);
    console.log(authData);
    const { data } = yield call(api.post, url, post, makeOptions(authData));

    yield put(QualityActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Qualidade da Análise cadastrada!", "quality.new", language);
    console.log(history);
    if (history) {
      history.push("/config/quality");
    }
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(QualityActions.failure(err.response.data.error.message));
  }
}
