import React from "react";

import { Container, theme } from "./styles";

function Icon({ status }) {

  function Theme() {
    switch (status) {
      case "Good for sale":
        return theme.goodsale;
      case "Pending":
        return theme.pending;
      case "Negotiation":
        return theme.negotiation;
      case "Other":
        return theme.question;
      case "Rework":
        return theme.rework;
      case "Return":
        return theme.return;
      case "Shelf life extension":
        return theme.shelflifeextension;
      case "Temporary blocked":
        return theme.temporaryblocked;
      case "Write of":
        return theme.writeoff;
      default:
          return theme.question;
        break;
    }
  }

  function Tit() {
    switch (status) {
      case "goodsale":
        return "Good for sale / Good for production";
      case "pending":
        return "Pending Analysis";
      case "negotiation":
        return "Return / Negotiation 3PP";
      case "question":
        return "Other";
      case "return":
        return "Return / Export";
      case "rework":
        return "Rework";
      case "shelflifeextension":
        return "Shelf life extension";
      case "temporaryblocked":
        return "Temporary blocked";
      case "writeoff":
        return "Write off";
      default:
        break;
    }
  }
  return <Container themes={Theme()} title={Tit()}></Container>;
}

export default Icon;
