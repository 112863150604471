import React, { useRef } from "react";
import * as Yup from "yup";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";

import { Container, WrapFilter, Row, WrapBtns } from "./styles";
import { Form } from "@unform/web";
import { Input } from "~/components/Form";
import { Button } from "~/styles/components";
import Text from "~/components/Text";

function Filter({ active, onfilter, filterPage, onPageChange }) {
  const formLogin = useRef();
  function closeFilter() {
    onfilter(!active);
  }

  async function handleSubmit(data) {
    try {
      let filtersForEnpoint = {};
      let index = 0;
      if (data.batch !== "") {
        filtersForEnpoint[`filters[${index}][like][batch]`] = data.batch;
        index++;
      }
      if (data.plant !== "") {
        filtersForEnpoint.plant_name = data.plant;
        index++;
      }
      if (data.legacy !== "") {
        filtersForEnpoint.legacy_name = data.legacy;
        index++;
      }
      if (data.code !== "") {
        filtersForEnpoint.product_code = data.code;
        index++;
      }
      if (data.description !== "") {
        filtersForEnpoint.product_description = data.description;
        index++;
      }

      filterPage(filtersForEnpoint);

      closeFilter();
      onPageChange(1);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container className={active ? "ativo" : ""}>
      <WrapFilter className={active ? "ativo" : ""}>
        <header>
          <p>
            <Text id="filtrar" dm="Filtrar" /> NPI
          </p>
          <CloseOutline size={20} onClick={closeFilter} />
        </header>
        <Form ref={formLogin} onSubmit={handleSubmit}>
          <Row templatecolumns="1">
            <Input
              label={<Text id="inventory.plant" dm="Planta" />}
              name="plant"
              placeholder=""
            />
          </Row>
          <Row templatecolumns="1">
            <Input
              label={<Text id="inventory.legacy" dm="Empresa" />}
              name="legacy"
              placeholder=""
            />
          </Row>
          <Row templatecolumns="1">
            <Input
              label={<Text id="inventory.batch" dm="Lote" />}
              name="batch"
              placeholder=""
            />
          </Row>
          <Row templatecolumns="1">
            <Input
              label={<Text id="inventory.dcode" dm="Código" />}
              name="code"
              placeholder=""
            />
          </Row>
          <Row templatecolumns="1">
            <Input
              label={<Text id="inventory.description" dm="Descrição" />}
              name="description"
              placeholder=""
            />
          </Row>
          <WrapBtns>
            <Button type="reset" btStyle="secondary" onClick={closeFilter}>
              <Text id="cancelar" dm="Cancelar" />
            </Button>
            <Button type="submit" btStyle="primary">
              <Text id="filtrar" dm="Filtrar" />
            </Button>
          </WrapBtns>
        </Form>
      </WrapFilter>
    </Container>
  );
}

export default Filter;
