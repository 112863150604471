import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const Radio = styled.input.attrs({
  type: "checkbox",
})`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 2px ${({ theme }) => theme.colors.lightDarkBlue} solid;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.lightDarkBlue : "transparent"};
  margin: 0 14px 0 2px;
  transition: all 300ms ease;
  appearance: none;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
  &:checked {
    background: ${({ theme }) => theme.colors.lightDarkBlue};
  }
`;

export const Content = styled.a`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns:
    repeat(5, minmax(100px, auto)) 2fr repeat(3, minmax(160px, auto))
    32px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 16px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 200ms ease;

  :hover {
    transform: scale(1.015);
  }
`;

export const Value = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.dark};
`;
