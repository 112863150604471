import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import IniciativeActions from "../../ducks/initiative/new";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* createIniciative(action) {
  try {
    const { post, history } = action;
    const url = `initiative`;
    const authData = yield select(getAuthData);
    console.log(authData);
    const { data } = yield call(api.post, url, post, makeOptions(authData));

    yield put(IniciativeActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Iniciativa cadastrada!", "initiative.new", language);
    console.log(history);
    if (history) {
      history.push("/config/iniciative");
    }
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(IniciativeActions.failure(err.response.data.error.message));
  }
}
