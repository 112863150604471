import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./config/ReactotronConfig";
import { toast } from "react-toastify";
import { store, persistor } from "./store";
import Routes from "./routes";

import GlobalStyle from "./styles/global";
import "react-toastify/dist/ReactToastify.css";
import Theme from "./styles/Theme";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Lang from "./lang";

toast.configure();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Lang>
          <Theme>
            <GlobalStyle />
            <BrowserRouter basename="/">
              <Routes />
            </BrowserRouter>
          </Theme>
        </Lang>
      </PersistGate>
    </Provider>
  );
}

export default App;
