import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ProductBranchActionActions from "../ducks/productBranchAction";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* createProductBranchAction(action) {
  try {
    const { post, history } = action;
    const url = `product-branch-action/`;
    const authData = yield select(getAuthData);
    console.log(authData);
    const { data } = yield call(api.post, url, post, makeOptions(authData));
    yield put(ProductBranchActionActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Cadastrado com sucesso!", "product_branch_action.new", language);
    if (history) {
      history.push("/");
    }
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(
      ProductBranchActionActions.failure(err.response.data.error.message)
    );
  }
}
