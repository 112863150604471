import React from "react";
import { Route, NavLink, useHistory } from "react-router-dom";
import { Container, SubMenu } from "./styles";
import { useFormatMessage } from "react-intl-hooks";

function Menu() {
  const history = useHistory();
  const text = useFormatMessage();
  function actual() {
    switch (history.location.pathname) {
      case "/products":
        return "active";
      case "/config/responsible":
        return "active";
      case "/config/segment":
        return "active";
      case "/config/type":
        return "active";
      case "/config/import":
        return "active";
      default:
        break;
    }
  }
  return (
    <>
      <Container>
        <Route>
          <NavLink to="/dashboard">
            {text({ id: "menu.dashboard", defaultMessage: "Dashboard" })}
          </NavLink>
          <NavLink to="/inventory">
            {text({ id: "menu.inventory", defaultMessage: "Inventory" })}
          </NavLink>
          <span className={actual() ? "submenu active" : "submenu"}>
            {text({ id: "menu.config", defaultMessage: "Configurações" })}
            <SubMenu>
              <NavLink to="/products">
                {text({ id: "menu.produtos", defaultMessage: "Produtos" })}
              </NavLink>
              <NavLink to="/config/import">
                {text({ id: "menu.import", defaultMessage: "Import" })}
              </NavLink>
              <NavLink to="/config/financial-import">
                {text({ id: "menu.financialimport", defaultMessage: "Financial Import" })}
              </NavLink>
              <NavLink to="/config/type">
                {text({
                  id: "menu.classificacao",
                  defaultMessage: "Tipo",
                })}
              </NavLink>
              <NavLink to="/config/responsible">
                {text({
                  id: "menu.responsavel",
                  defaultMessage: "Responsável",
                })}
              </NavLink>
              <NavLink to="/config/segment">
                {text({ id: "menu.segmento", defaultMessage: "Segmento" })}
              </NavLink>
              <NavLink to="/config/initiative">
                {text({ id: "menu.iniciativa", defaultMessage: "Iniciativa" })}
              </NavLink>
              <NavLink to="/config/quality">
                {text({ id: "menu.qualidade", defaultMessage: "Qualidade" })}
              </NavLink>
              <NavLink to="/config/users">
                {text({ id: "menu.usuario", defaultMessage: "Usuários" })}
              </NavLink>
            </SubMenu>
          </span>
        </Route>
      </Container>
    </>
  );
}

export default Menu;
