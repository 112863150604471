import { call, put, select } from "redux-saga/effects";
import { notify } from "../../services/notification";
import api from "../../services/api";
import AuthActions from "../ducks/auth";

const getLanguage = (state) => state.language;

export function* login(action) {
  try {
    const { data: post, history } = action;
    const { data } = yield call(api.post, "login", post);
    const {data: language} = yield select(getLanguage);
    notify("success", "Olá seja bem-vindo!", "auth.login", language);
    if (history) {
      history.push("/");
    }
    // yield put(AuthActions.success({ ...data.user[0], token: data.token }));
    yield put(AuthActions.success(data));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(AuthActions.failure(err.response.data.error.message));
  }
}
