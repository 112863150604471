import React from "react";

import Slider from "react-slick";

import { Container } from "./styles";
import CardStatus from "../CardStatus";

function Header() {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 1681,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 780,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container>
      <Slider {...settings}>
        <CardStatus />
        <CardStatus />
        <CardStatus />
        <CardStatus />
        <CardStatus />
        <CardStatus />
        <CardStatus />
        <CardStatus />
      </Slider>
    </Container>
  );
}

export default Header;
