import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import LanguageActions from "~/store/ducks/language";

import { Container, Icon } from "./styles";

import pt from "~/assets/icons/icon-pt.svg";
import en from "~/assets/icons/icon-en.svg";
import es from "~/assets/icons/icon-es.svg";

function Language() {
  const dispatch = useDispatch();

  const { data: dataLanguage } = useSelector((state) => state.language);

  const changeLang = (data) => {
    dispatch(LanguageActions.request(data));
  };

  return (
    <Container>
      <Icon
        src={pt}
        alt="Português"
        title="Português"
        onClick={() => changeLang("pt")}
        opacity={dataLanguage === "pt" ? "1" : "0.4"}
      />
      <Icon
        src={en}
        alt="Inglês"
        title="Inglês"
        onClick={() => changeLang("en")}
        opacity={dataLanguage === "en" ? "1" : "0.4"}
      />
      <Icon
        src={es}
        alt="Espanhol"
        title="Espanhol"
        onClick={() => changeLang("es")}
        opacity={dataLanguage === "es" ? "1" : "0.4"}
      />
    </Container>
  );
}

export default Language;
