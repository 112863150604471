import { all, takeLatest } from "redux-saga/effects";

// auth
import { AuthTypes } from "../ducks/auth";
import { login } from "./auth";

//Users
import { UsersTypes } from "../ducks/user/list";
import { fetchUsers } from "./user/list";

//Products
import { ProductTypes } from "../ducks/product/new";
import { createProduct } from "./product/new";

import { ProductsTypes } from "../ducks/product/list";
import { fetchProducts } from "./product/list";

import { ProductShowTypes } from "../ducks/product/show";
import { productShow } from "./product/show";
import { ProductEditTypes } from "../ducks/product/edit";
import { productEdit } from "./product/edit";
import { fetchProductType } from "./product/type";
import { ProductTypeTypes } from "../ducks/product/type";
import { fetchProductUnit } from "./product/unit";
import { ProductUnitTypes } from "../ducks/product/unit";
import { productDelete } from "./product/delete";
import { ProductDeleteTypes } from "../ducks/product/delete";

//Responsible
import { ResponsibleTypes } from "../ducks/responsible/new";
import { createResponsible } from "./responsible/new";
import { ResponsiblesTypes } from "../ducks/responsible/list";
import { fetchResponsible } from "./responsible/list";
import { ResponsibleShowTypes } from "../ducks/responsible/show";
import { responsibleShow } from "./responsible/show";
import { ResponsibleEditTypes } from "../ducks/responsible/edit";
import { responsibleEdit } from "./responsible/edit";
import { ResponsibleDeleteTypes } from "../ducks/responsible/delete";
import { responsibleDelete } from "./responsible/delete";

//Classification
import { ClassificationTypes } from "../ducks/classification/new";
import { createClassification } from "./classification/new";
import { ClassificationsTypes } from "../ducks/classification/list";
import { fetchClassification } from "./classification/list";
import { ClassificationShowTypes } from "../ducks/classification/show";
import { classificationShow } from "./classification/show";
import { ClassificationEditTypes } from "../ducks/classification/edit";
import { classificationEdit } from "./classification/edit";
import { ClassificationDeleteTypes } from "../ducks/classification/delete";
import { classificationDelete } from "./classification/delete";

//Segment
import { SegmentTypes } from "../ducks/segment/new";
import { createSegment } from "./segment/new";
import { SegmentsTypes } from "../ducks/segment/list";
import { fetchSegments } from "./segment/list";
import { SegmentShowTypes } from "../ducks/segment/show";
import { segmentShow } from "./segment/show";
import { SegmentEditTypes } from "../ducks/segment/edit";
import { segmentEdit } from "./segment/edit";
import { SegmentDeleteTypes } from "../ducks/segment/delete";
import { segmentDelete } from "./segment/delete";

//Iniciative
import { IniciativeTypes } from "../ducks/initiative/new";
import { createIniciative } from "./initiative/new";
import { IniciativesTypes } from "../ducks/initiative/list";
import { fetchIniciative } from "./initiative/list";
import { IniciativeShowTypes } from "../ducks/initiative/show";
import { iniciativeShow } from "./initiative/show";
import { IniciativeEditTypes } from "../ducks/initiative/edit";
import { iniciativeEdit } from "./initiative/edit";
import { IniciativeDeleteTypes } from "../ducks/initiative/delete";
import { iniciativeDelete } from "./initiative/delete";

//Import Inventory
import { ImportInventoryTypes } from "../ducks/importInventory";
import { createImportInventory } from "./importInventory";

//Import Financial
import { ImportFinancialTypes } from "../ducks/importFinancial";
import { createImportFinancial } from "./importFinancial";

// Product Branch Plant
// import { ProductBranchPlantTypes } from "../ducks/productBranchPlant/new";
// import { createProductBranchPlant } from "./productBranchPlant/new";
import { ProductBranchPlantsTypes } from "../ducks/productBranchPlant/list";
import { fetchProductBranchPlants } from "./productBranchPlant/list";
import { ProductBranchPlantShowTypes } from "../ducks/productBranchPlant/show";
import { productBranchPlantShow } from "./productBranchPlant/show";
import { ProductBranchPlantEditTypes } from "../ducks/productBranchPlant/edit";
import { productBranchPlantEdit } from "./productBranchPlant/edit";
// import { ProductBranchPlantDeleteTypes } from "../ducks/productBranchPlant/delete";
// import { productBranchPlantDelete } from "./productBranchPlant/delete";

//Std Status
import { StdsStatusTypes } from "../ducks/std/list";
import { fetchStdsStatus } from "./std/list";

//Sla
import { SlasTypes } from "../ducks/sla/list";
import { fetchSla } from "./sla/list";

//Quality
import { QualityTypes } from "../ducks/quality/new";
import { createQuality } from "./quality/new";
import { QualitiesTypes } from "../ducks/quality/list";
import { fetchQuality } from "./quality/list";
import { QualityShowTypes } from "../ducks/quality/show";
import { qualityShow } from "./quality/show";
import { QualityEditTypes } from "../ducks/quality/edit";
import { qualityEdit } from "./quality/edit";
import { QualityDeleteTypes } from "../ducks/quality/delete";
import { qualityDelete } from "./quality/delete";

//Financial Import
import { FinancialImportsTypes } from "../ducks/financialImport/list";
import { fetchFinancialImports } from "./financialImport/list";

//Product Branch Action
import { ProductBranchActionTypes } from "../ducks/productBranchAction";
import { createProductBranchAction } from "./productBranchAction";

//Language
import { LanguageTypes } from "../ducks/language";
import { fetchLanguage } from "./language";

export default function* rootSaga() {
  yield all([
    // auth
    takeLatest(AuthTypes.REQUEST, login),

    //Users
    takeLatest(UsersTypes.REQUEST, fetchUsers),

    //Products
    takeLatest(ProductTypes.REQUEST, createProduct),
    takeLatest(ProductsTypes.REQUEST, fetchProducts),
    takeLatest(ProductShowTypes.REQUEST, productShow),
    takeLatest(ProductEditTypes.REQUEST, productEdit),
    takeLatest(ProductTypeTypes.REQUEST, fetchProductType),
    takeLatest(ProductUnitTypes.REQUEST, fetchProductUnit),
    takeLatest(ProductDeleteTypes.REQUEST, productDelete),

    //responsible
    takeLatest(ResponsibleTypes.REQUEST, createResponsible),
    takeLatest(ResponsiblesTypes.REQUEST, fetchResponsible),
    takeLatest(ResponsibleShowTypes.REQUEST, responsibleShow),
    takeLatest(ResponsibleEditTypes.REQUEST, responsibleEdit),
    takeLatest(ResponsibleDeleteTypes.REQUEST, responsibleDelete),

    //Classification
    takeLatest(ClassificationTypes.REQUEST, createClassification),
    takeLatest(ClassificationsTypes.REQUEST, fetchClassification),
    takeLatest(ClassificationShowTypes.REQUEST, classificationShow),
    takeLatest(ClassificationEditTypes.REQUEST, classificationEdit),
    takeLatest(ClassificationDeleteTypes.REQUEST, classificationDelete),

    //Segment
    takeLatest(SegmentTypes.REQUEST, createSegment),
    takeLatest(SegmentsTypes.REQUEST, fetchSegments),
    takeLatest(SegmentShowTypes.REQUEST, segmentShow),
    takeLatest(SegmentEditTypes.REQUEST, segmentEdit),
    takeLatest(SegmentDeleteTypes.REQUEST, segmentDelete),

    //Iniciative
    takeLatest(IniciativeTypes.REQUEST, createIniciative),
    takeLatest(IniciativesTypes.REQUEST, fetchIniciative),
    takeLatest(IniciativeShowTypes.REQUEST, iniciativeShow),
    takeLatest(IniciativeEditTypes.REQUEST, iniciativeEdit),
    takeLatest(IniciativeDeleteTypes.REQUEST, iniciativeDelete),

    //Import Inventory
    takeLatest(ImportInventoryTypes.REQUEST, createImportInventory),

    //Import Financial
    takeLatest(ImportFinancialTypes.REQUEST, createImportFinancial),

    //Product Branch Plant
    // takeLatest(ProductBranchPlantTypes.REQUEST, createProductBranchPlant),
    takeLatest(ProductBranchPlantsTypes.REQUEST, fetchProductBranchPlants),
    takeLatest(ProductBranchPlantShowTypes.REQUEST, productBranchPlantShow),
    takeLatest(ProductBranchPlantEditTypes.REQUEST, productBranchPlantEdit),
    // takeLatest(ProductBranchPlantDeleteTypes.REQUEST, productBranchPlantDelete),

    //Std Status
    takeLatest(StdsStatusTypes.REQUEST, fetchStdsStatus),

    //Sla
    takeLatest(SlasTypes.REQUEST, fetchSla),

    //Quality
    takeLatest(QualityTypes.REQUEST, createQuality),
    takeLatest(QualitiesTypes.REQUEST, fetchQuality),
    takeLatest(QualityShowTypes.REQUEST, qualityShow),
    takeLatest(QualityEditTypes.REQUEST, qualityEdit),
    takeLatest(QualityDeleteTypes.REQUEST, qualityDelete),

    //Financial Import
    takeLatest(FinancialImportsTypes.REQUEST, fetchFinancialImports),

    //Product Branch Action
    takeLatest(ProductBranchActionTypes.REQUEST, createProductBranchAction),

    //Language
    takeLatest(LanguageTypes.REQUEST, fetchLanguage),
  ]);
}
