import React, { useEffect, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QualitiesActions from "~/store/ducks/quality/list";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";

import Header from "~/components/Header";
import Loading from "~/components/Loading";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  ListInit,
  ItemInit,
} from "../styles";
import { ButtonMini } from "~/styles/components";

function Qualities() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state.qualities);

  const fectQualities = useCallback(() => {
    dispatch(QualitiesActions.request());
  }, [dispatch]);

  useEffect(() => {
    fectQualities();
  }, [fectQualities]);

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="quality.title" dm="Qualidade de Análise" />
          </small>
        </Title>
        <span>
          <ButtonMini
            btStyle="primary"
            onClick={() => history.push("/config/quality/new")}>
            <Text id="quality.btnnew" dm="Nova Qualidade de Análise" />
          </ButtonMini>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      {/* <button onClick={() => setLanguage("en")}>aquiii</button> */}
      <Content>
        <ListInit>
          <header>
            <p>
              <Text id="quality.codigo" dm="Código" />
            </p>
            <p>
              <Text id="quality.descricao" dm="Descrição" />
            </p>
          </header>
          {loading ? (
            <Loading />
          ) : (
            <>
              {data.data.map((item) => (
                <Link to={`/config/quality/${item.id}`} key={item.id}>
                  <ItemInit key={item.id}>
                    <p>{item.id}</p>
                    <p>{item.name}</p>
                  </ItemInit>
                </Link>
              ))}
            </>
          )}
        </ListInit>
      </Content>
    </Container>
  );
}

export default Qualities;
