import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions, queryBuilder } from "~/services/api";
import SlasActions from "../../ducks/sla/list";

const getAuthData = (state) => state.auth.data;

export function* fetchSla(action) {
  try {
    const { params } = action;
    const query = params ? queryBuilder(params) : "";
    const url = `sla?${query}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.get, url, makeOptions(authData));
    yield put(SlasActions.success(data));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(SlasActions.failure(err.response.data.error.message));
  }
}
