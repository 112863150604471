import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Radio, Content, Value } from "./styles";
import Icon from "../../Icon";

function ItemNPI(props) {
  const history = useHistory();
  const [check, setCheck] = useState(false);

  function name(params) {}

  return (
    <Container>
      <Radio
        name="teste"
        onClick={(e) => props.verifycheck(e.target.checked, props)}
        defaultChecked={check}
      />
      <Content
        onClick={() => history.push(`/product-details/${props.id}`, props.id)}>
        <Value>{props.date}</Value>
        <Value>{props.plant}</Value>
        <Value>{props.legacy}</Value>
        <Value>{props.batch}</Value>
        <Value>{props.dcode}</Value>
        <Value>{props.description}</Value>
        <Value>{props.stl}</Value>
        <Value>{props.stock}</Value>
        <Value>{props.usd}</Value>
        <Icon status={props.status} />
      </Content>
    </Container>
  );
}

export default ItemNPI;
