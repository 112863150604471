import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ImportInventoryActions from "../ducks/importInventory";

const getAuthData = (state) => state.auth.data;

export function* createImportInventory(action) {
  try {
    const { post, history } = action;
    const url = `inventory-stock/import/`;
    const authData = yield select(getAuthData);
    console.log(authData);
    const { data } = yield call(api.post, url, post, makeOptions(authData));

    const response = yield put(ImportInventoryActions.success(data));
    const { DATA: msg } = response.data;
    notify("success", msg);

    if (history) {
      history.push("/");
    }
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(ImportInventoryActions.failure(err.response.data.error.message));
  }
}
