import React, { useRef, useState, useEffect, useCallback } from "react";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import { useDispatch, useSelector } from "react-redux";
import ImportFinancialActions from "~/store/ducks/importFinancial";
import FinancialImportsActions from "~/store/ducks/financialImport/list";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";
import XLSX from "xlsx";

import Header from "~/components/Header";
import { Input } from "~/components/Form";
import Loading from "~/components/Loading";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapForm,
} from "../styles";
import { StatusBar, StatusItem } from './styles';
import { ButtonMini } from "~/styles/components";

function Responsible() {
  const dowForm = useRef();
  const dupontForm = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dowActive, setDowActive] = useState(false);
  const [dowImportDate, setDowImportDate] = useState(null);
  const [dupontActive, setDupontActive] = useState(false);
  const [dupontImportDate, setDupontImportDate] = useState(null);

  const { data, loading: loadingImportFinancial } = useSelector(
    (state) => state.importFinancial
  );
  const { data: importList } = useSelector(
    (state) => state.financialImports
  );

  const fetchFinancialImport = useCallback(() => {
    dispatch(FinancialImportsActions.request(
      `filters[0][like][import_date]=${moment.utc().format('YYYY-MM-DD')}`
    ));
  }, [dispatch]);

  useEffect(() => {
    fetchFinancialImport();
  }, [fetchFinancialImport, data]);

  useEffect(() => {
    if(importList && importList.data) {
      const dupontList = importList.data.filter(item => item.legacy === 'dupont');
      const dowList = importList.data.filter(item => item.legacy === 'dow');

      if(dupontList && dupontList.length > 0) {
        setDupontActive(true);
        setDupontImportDate(dupontList[0].import_date)
      }

      if(dowList && dowList.length > 0) {
        setDowActive(true);
        setDowImportDate(dowList[0].import_date)
      }
    }
  }, [importList]);


  const getWorkbookFromFile = async (excelFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary", cellDates: true });
        resolve(workbook);
      };
      reader.readAsBinaryString(excelFile);
    });
  };

  const handleDupontImport = async (file) => {
    const route = 'dupont';
    const name = file[0].name.split(".");
    const index = name.length - 1;
    if (
      name[index] === "xlsx" ||
      name[index] === "xls" ||
      name[index] === "XLSX" ||
      name[index] === "XLS"
    ) {
      setLoading(true);
      setFileData([]);
      const workbook = await getWorkbookFromFile(file[0]);
      let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const [, end]   = first_worksheet['!ref'].split(':');
      first_worksheet['!ref'] = `B4:${end}`;
      const newFileData = await XLSX.utils.sheet_to_json(first_worksheet, {
        header: 0,
      });
      setLoading(false);
      await sendImport(newFileData, route);
    } else {
      setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
      setLoading(false);
    }
  };

  const handleDowImport = async (file) => {
    const route = 'dow';
    const name = file[0].name.split(".");
    const index = name.length - 1;
    if (
      name[index] === "xlsx" ||
      name[index] === "xls" ||
      name[index] === "XLSX" ||
      name[index] === "XLS"
    ) {
      setLoading(true);
      setFileData([]);
      const workbook = await getWorkbookFromFile(file[0]);
      let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const newFileData = await XLSX.utils.sheet_to_json(first_worksheet, {
        header: 0,
      });
      setLoading(false);
      await sendImport(newFileData, route);
    } else {
      setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
      setLoading(false);
    }
  };

  const sendImport = async (newFileData, route) => {
    try {
      console.log("####Começou", data);
      dispatch(ImportFinancialActions.request({ file: newFileData, route }));
      console.log("####Terminou", data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="financialimport.title" dm="Financial Import" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <StatusBar>
          <StatusItem active={dowActive}>
            <strong>
              DOW
            </strong>
            {dowActive &&
              <small>
                 <Text id="financialimport.msg" dm="Importado em" />{' '}
                 {dowImportDate && moment(dowImportDate).format('DD/MM HH:mm')}
              </small>
            }
          </StatusItem>
          <StatusItem active={dupontActive}>
            <strong>
              DUPONT
            </strong>
            {dupontActive &&
              <small>
                <Text id="financialimport.msg" dm="Importado em" />{' '}
                {dupontImportDate && moment(dupontImportDate).format('DD/MM HH:mm')}
              </small>
            }
          </StatusItem>
        </StatusBar>
        <SubTitle>
          <Text id="financialimport.upload" dm="Subir arquivo" />
        </SubTitle>
        {loadingImportFinancial || loading ? (
          <Loading />
        ) : (
          <>
          <Form ref={dowForm}>
            <WrapForm>
              <Row templatecolumns="1">
                <Input
                  label={<Text id="financialimport.dow" dm="DOW" />}
                  type="file"
                  name="import"
                  onChange={(event) =>
                    event.target.files[0] && handleDowImport(event.target.files)
                  }
                />
              </Row>
            </WrapForm>
          </Form>
           <Form ref={dupontForm} >
           <WrapForm>
             <Row templatecolumns="1">
               <Input
                 label={<Text id="financialimport.dupont" dm="DUPONT" />}
                 type="file"
                 name="import"
                 onChange={(event) =>
                   event.target.files[0] && handleDupontImport(event.target.files)
                 }
               />
             </Row>
           </WrapForm>
         </Form>
         </>
        )}
      </Content>
    </Container>
  );
}

export default Responsible;
