import React, { useRef, useEffect } from "react";
import ReactSelect from "react-select";
import { useField } from "@unform/core";
import { Container } from "./styles";

export default function Select({ name, label, required, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      padding: 8,
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontFamily: "Open Sans",
      fontSize: 14,
      color: "#A2258D",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: `1px #ddd solid`,
      padding: 8,
      fontFamily: "Open Sans",
      fontSize: 14,
      boxSizing: "border-box",
      outline: "none",
      backgroundColor: state.isSelected ? "#A2258D" : "transparent",
    }),
  };
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "state.value",
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container>
      <label htmlFor={fieldName}>
        {label}
        {required && <span className="danger">*</span>}
      </label>
      <ReactSelect
        name={fieldName}
        styles={customStyles}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      {error && <span className="danger">{error}</span>}
    </Container>
  );
}
