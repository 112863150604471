import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions, queryBuilder } from "~/services/api";
import ProductsBranchPlantsActions from "../../ducks/productBranchPlant/list";

const getAuthData = (state) => state.auth.data;

export function* fetchProductBranchPlants(action) {
  try {
    const { params } = action;
    const query = params ? queryBuilder(params) : "";
    const url = `product-branch-plant?${query}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.get, url, makeOptions(authData));
    yield put(ProductsBranchPlantsActions.success(data.data, data.pagination));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ProductsBranchPlantsActions.failure(err.response.data.error.message)
    );
  }
}
