import styled from "styled-components";

export const Container = styled.section`
  margin: 40px 0;
  padding: 0 24px;
  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.colors.lightDarkBlue};
  }
`;
