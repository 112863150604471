import React, { useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";

import { useDispatch, useSelector } from "react-redux";
import ProductActions from "~/store/ducks/product/new";
import ProductTypeTypes from "~/store/ducks/product/type";
import ProductUnitTypes from "~/store/ducks/product/unit";
import SegmentsActions from "~/store/ducks/segment/list";
import ClassificationsActions from "~/store/ducks/classification/list";
import ResponsiblesActions from "~/store/ducks/responsible/list";

import { PackageIcon, LeftArrowAlt } from "@styled-icons/boxicons-regular";

import Header from "~/components/Header";
import { Input, Select } from "~/components/Form";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../styles";
import { ButtonMini, Button } from "~/styles/components";

function ProductNew() {
  const formLogin = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: dataSegmento } = useSelector((state) => state.segments);
  const { data: dataProductType } = useSelector((state) => state.productType);
  const { data: dataProductUnit } = useSelector((state) => state.productUnit);
  const { data: dataClassification } = useSelector(
    (state) => state.classifications
  );

  const { data: dataResponsible } = useSelector((state) => state.responsibles);

  const fectSegments = useCallback(() => {
    dispatch(SegmentsActions.request());
    dispatch(ClassificationsActions.request());
    dispatch(ResponsiblesActions.request());
    dispatch(ProductTypeTypes.request());
    dispatch(ProductUnitTypes.request());
  }, [dispatch]);

  useEffect(() => {
    fectSegments();
  }, [fectSegments]);

  async function handleSubmit(data) {
    try {
      console.log(data);
      formLogin.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required("Digite a descrição"),
        product_units_id: Yup.string().required("Digite a Unidade de medida"),
        code: Yup.string().required("Digite o código sap"),
        product_types_id: Yup.string()
          .typeError("Selecione o tipo")
          .required("Selecione o tipo"),
        quantity_unit: Yup.string().required("Digite a quantidade"),
        quantity_pallet: Yup.string().required("Digite a quantidade"),
        quantity_ballast: Yup.string().required("Digite a quantidade"),
        quantity_piled: Yup.string().required("Digite a quantidade"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(ProductActions.request(data));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  const unitOptions =
    dataProductUnit &&
    dataProductUnit.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const typeOptions =
    dataProductType &&
    dataProductType.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const segOptions =
    dataSegmento &&
    dataSegmento.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const resOptions =
    dataResponsible &&
    dataResponsible.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const classOptions =
    dataClassification &&
    dataClassification.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <PackageIcon size={24} />
          <Text id="produtos" dm="Produtos" />
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="produtos.info" dm="Informações do produto" />
        </SubTitle>
        <Form ref={formLogin} onSubmit={handleSubmit}>
          <WrapForm>
            <Row className="tc2-1">
              <Input
                label={<Text id="produtos.descr" dm="Descrição" />}
                name="name"
              />
              {dataProductUnit && dataProductUnit.data && (
                <Select
                  label={<Text id="produtos.medida" dm="Unidade de Medida" />}
                  name="product_units_id"
                  placeholder="Selecione a unidade..."
                  options={unitOptions}
                />
              )}
            </Row>
            <Row templatecolumns="3">
              <Input
                label={<Text id="produtos.sap" dm="Código SAP" />}
                name="code"
              />
              {dataProductType && (
                <Select
                  label={<Text id="produtos.tipo" dm="Tipo" />}
                  name="product_types_id"
                  placeholder="Selecione o tipo"
                  options={typeOptions}
                />
              )}
              <Input
                label={<Text id="produtos.qtdkgun" dm="QTD. KG/L Unidade" />}
                name="quantity_unit"
              />
            </Row>
            <Row templatecolumns="3">
              <Input
                label={<Text id="produtos.qtdunpa" dm="QTD. Unidade/Pallet" />}
                name="quantity_pallet"
              />
              <Input
                label={<Text id="produtos.qtdlas" dm="QTD. Lastro" />}
                name="quantity_ballast"
              />
              <Input
                label={<Text id="produtos.qtdemp" dm="QTD. Empilhado" />}
                name="quantity_piled"
              />
            </Row>
            <Row templatecolumns="3">
              {dataSegmento && (
                <Select
                  label={<Text id="segmento.title" dm="Segmento" />}
                  name="segment_id"
                  placeholder="Selecione o segmento"
                  options={segOptions}
                />
              )}
              {dataResponsible && (
                <Select
                  label={<Text id="respon.title" dm="Responsável" />}
                  name="responsible_id"
                  placeholder="Selecione o responsável"
                  options={resOptions}
                />
              )}
            </Row>
          </WrapForm>
          <WrapBtns>
            <Button
              type="reset"
              btStyle="secondary"
              onClick={() => history.goBack()}>
              <Text id="cancelar" dm="Cancelar" />
            </Button>
            <Button type="submit" btStyle="primary">
              <Text id="salvar" dm="Salvar" />
            </Button>
          </WrapBtns>
        </Form>
      </Content>
    </Container>
  );
}

export default ProductNew;
