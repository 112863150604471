import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ProductsBranchPlantsShowActions from "../../ducks/productBranchPlant/show";

const getAuthData = (state) => state.auth.data;

export function* productBranchPlantShow(action) {
  try {
    const { id } = action;
    const url = `product-branch-plant?filters[0][equal][id]=${id}`;
    console.log(url);
    const authData = yield select(getAuthData);
    const { data } = yield call(api.get, url, makeOptions(authData));

    yield put(ProductsBranchPlantsShowActions.success(data.data));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ProductsBranchPlantsShowActions.failure(err.response.data.error.message)
    );
  }
}
