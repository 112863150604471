import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    white: "#fff",
    black: "#2B2B2B",
    dark: "#292D41",
    darkRgba15: "rgba(41,45,65, 0.15)",
    darkRgba8: "rgba(41,45,65, 0.08)",
    blueDark: "#43475C",
    lightDarkBlue: "#616788",
    primary: "#1976D2",
    danger: "#ec1751",
  },
  fonts: {
    titleRegular: "Gilroy-Regular",
    titleBold: "Gilroy-Bold",
    titleExtraBold: "Gilroy-ExtraBold",
    titleSemiBold: "Gilroy-SemiBold",
    titleLight: "Gilroy-Light",
    defautl: "Open Sans",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
