import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { useParams } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import ProducShowtActions from "~/store/ducks/product/show";
import ProductEdit from "~/store/ducks/product/edit";
import ProductTypeTypes from "~/store/ducks/product/type";
import ProductUnitTypes from "~/store/ducks/product/unit";
import SegmentsActions from "~/store/ducks/segment/list";
import ClassificationsActions from "~/store/ducks/classification/list";
import ResponsiblesActions from "~/store/ducks/responsible/list";
import ProductDeleteActions from "~/store/ducks/product/delete";

import { PackageIcon, LeftArrowAlt } from "@styled-icons/boxicons-regular";

import Header from "~/components/Header";
import Loading from "~/components/Loading";
import { Input, Select } from "~/components/Form";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../styles";
import { ButtonMini, Button } from "~/styles/components";

function ProductNew() {
  const formLogin = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  let { id } = useParams();

  const { data: dataProduct, loading: loadingProduct } = useSelector(
    (state) => state.productShow
  );

  const { data: dataProductType } = useSelector((state) => state.productType);
  const { data: dataProductUnit } = useSelector((state) => state.productUnit);
  const { data: dataSegmento } = useSelector((state) => state.segments);
  const {
    data: dataClassification,
    loading: loadingClassification,
  } = useSelector((state) => state.classifications);
  const { data: dataResponsible } = useSelector((state) => state.responsibles);

  useEffect(() => {
    dispatch(SegmentsActions.request());
    dispatch(ClassificationsActions.request());
    dispatch(ResponsiblesActions.request());
    dispatch(ProducShowtActions.request(id));
    dispatch(ProductTypeTypes.request());
    dispatch(ProductUnitTypes.request());
  }, [id]);

  async function handleSubmit(data) {
    try {
      formLogin.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required("Digite a descrição"),
        code: Yup.string().required("Digite o código sap"),
        product_units_id: Yup.string().required("Digite a Unidade de medida"),
        product_types_id: Yup.string().required("Selecione o tipo"),
        quantity_unit: Yup.string().required("Digite a quantidade"),
        quantity_pallet: Yup.string().required("Digite a quantidade"),
        quantity_ballast: Yup.string().required("Digite a quantidade"),
        quantity_piled: Yup.string().required("Digite a quantidade"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      dispatch(ProductEdit.request(id, data));
      console.log(data);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  async function handleDelete() {
    try {
      await dispatch(ProductDeleteActions.request(id));
      history.push("/products");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  const unitOptions =
    dataProductUnit &&
    dataProductUnit.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const typeOptions =
    dataProductType &&
    dataProductType.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const segOptions =
    dataSegmento &&
    dataSegmento.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const resOptions =
    dataResponsible &&
    dataResponsible.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const classOptions =
    dataClassification &&
    dataClassification.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <PackageIcon size={24} />
          <Text id="produtos" dm="Produtos" />
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="produtos.info" dm="Informações do produto" />
        </SubTitle>
        {loadingProduct ? (
          <Loading />
        ) : (
          <Form ref={formLogin} onSubmit={handleSubmit}>
            <WrapForm>
              <Row className="tc2-1">
                <Input
                  label={<Text id="produtos.descr" dm="Descrição" />}
                  name="name"
                  defaultValue={dataProduct.data.name}
                />
                {dataProduct && dataProductUnit && (
                  <Select
                    label={<Text id="produtos.medida" dm="Unidade de Medida" />}
                    name="product_units_id"
                    placeholder="Selecione a unidade"
                    options={unitOptions}
                    defaultValue={{
                      label: dataProductUnit && dataProduct.data.unit.name,
                      value: dataProductUnit && dataProduct.data.unit.id,
                    }}
                  />
                )}
              </Row>
              <Row templatecolumns="3">
                <Input
                  label={<Text id="produtos.sap" dm="Código SAP" />}
                  name="code"
                  defaultValue={dataProduct.data.code}
                />
                {dataProductType && (
                  <Select
                    label={<Text id="produtos.tipo" dm="Tipo" />}
                    name="product_types_id"
                    placeholder="Selecione o tipo"
                    options={typeOptions}
                    defaultValue={{
                      label: dataProductType && dataProduct.data.type.name,
                      value: dataProductType && dataProduct.data.type.id,
                    }}
                  />
                )}
                <Input
                  label={<Text id="produtos.qtdkgun" dm="QTD. KG/L Unidade" />}
                  name="quantity_unit"
                  defaultValue={dataProduct.data.quantity_unit}
                />
              </Row>
              <Row templatecolumns="3">
                <Input
                  label={
                    <Text id="produtos.qtdunpa" dm="QTD. Unidade/Pallet" />
                  }
                  name="quantity_pallet"
                  defaultValue={dataProduct.data.quantity_pallet}
                />
                <Input
                  label={<Text id="produtos.qtdlas" dm="QTD. Lastro" />}
                  name="quantity_ballast"
                  defaultValue={dataProduct.data.quantity_ballast}
                />
                <Input
                  label={<Text id="produtos.qtdemp" dm="QTD. Empilhado" />}
                  name="quantity_piled"
                  defaultValue={dataProduct.data.quantity_piled}
                />
              </Row>
              <Row templatecolumns="3">
                {dataSegmento && (
                  <Select
                    label={<Text id="segmento.title" dm="Segmento" />}
                    name="segment_id"
                    placeholder="Selecione o segmento"
                    options={segOptions}
                    defaultValue={{
                      label:
                        dataSegmento &&
                        dataProduct.data.segment &&
                        dataProduct.data.segment.name,
                      value:
                        dataSegmento &&
                        dataProduct.data.segment &&
                        dataProduct.data.segment.id,
                    }}
                  />
                )}
                {dataResponsible && (
                  <Select
                    label={<Text id="respon.title" dm="Responsável" />}
                    name="responsible_id"
                    placeholder="Selecione o responsável"
                    options={resOptions}
                    defaultValue={{
                      label:
                        dataResponsible &&
                        dataProduct.data.responsible &&
                        dataProduct.data.responsible.name,
                      value:
                        dataResponsible &&
                        dataProduct.data.responsible &&
                        dataProduct.data.responsible.id,
                    }}
                  />
                )}
              </Row>
            </WrapForm>
            <WrapBtns>
              <Button type="reset" onClick={handleDelete} btStyle="delete">
                <Text id="deletar" dm="Deletar" />
              </Button>
              <Button
                type="reset"
                btStyle="secondary"
                onClick={() => history.goBack()}>
                <Text id="cancelar" dm="Cancelar" />
              </Button>
              <Button type="submit" btStyle="primary">
                <Text id="salvar" dm="Salvar" />
              </Button>
            </WrapBtns>
          </Form>
        )}
      </Content>
    </Container>
  );
}

export default ProductNew;
