import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ImportFinancialActions from "../ducks/importFinancial";

const getAuthData = (state) => state.auth.data;

export function* createImportFinancial(action) {
  try {
    const { post, history } = action;
    const { file, route } = post;
    const url = `financial-import/import-${route}/`;
    const authData = yield select(getAuthData);
    console.log(authData);
    const { data } = yield call(api.post, url, file, makeOptions(authData));

    const response = yield put(ImportFinancialActions.success(data));
    const { DATA: msg } = response.data;
    notify("success", msg);

    if (history) {
      history.push("/");
    }
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(ImportFinancialActions.failure(err.response.data.error.message));
  }
}
