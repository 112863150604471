import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { useDispatch, useSelector } from "react-redux";
import SegmentShowActions from "~/store/ducks/segment/show";
import SegmentEditActions from "~/store/ducks/segment/edit";
import SegmentDeleteActions from "~/store/ducks/segment/delete";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";
import { useParams } from "react-router";

import Header from "~/components/Header";
import Loading from "~/components/Loading";
import { Input } from "~/components/Form";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../styles";
import { ButtonMini, Button } from "~/styles/components";

function Segment() {
  const formLogin = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  let { id } = useParams();

  const { data: dataSeg, loading: loadingSeg } = useSelector(
    (state) => state.segmentShow
  );

  useEffect(() => {
    dispatch(SegmentShowActions.request(id));
  }, [id]);

  async function handleSubmit(data) {
    try {
      formLogin.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required("Digite o seguimento"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      await dispatch(SegmentEditActions.request(id, data));
      history.push("/config/segment");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  async function handleDelete() {
    try {
      await dispatch(SegmentDeleteActions.request(id));
      history.push("/config/segment");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="segmento.title" dm="Segmento" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="informacoes" dm="Informações" />
        </SubTitle>
        {loadingSeg ? (
          <Loading />
        ) : (
          <Form ref={formLogin} onSubmit={handleSubmit}>
            <WrapForm>
              <Row templatecolumns="1">
                <Input
                  label={<Text id="segmento.title" dm="Segmento" />}
                  name="name"
                  defaultValue={dataSeg.data.name}
                />
              </Row>
            </WrapForm>
            <WrapBtns>
              <Button type="reset" onClick={handleDelete} btStyle="delete">
                <Text id="deletar" dm="Deletar" />
              </Button>
              <Button type="reset" btStyle="secondary">
                <Text id="cancelar" dm="Cancelar" />
              </Button>
              <Button type="submit" btStyle="primary">
                <Text id="salvar" dm="Salvar" />
              </Button>
            </WrapBtns>
          </Form>
        )}
      </Content>
    </Container>
  );
}

export default Segment;
