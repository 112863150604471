import { combineReducers } from "redux";

import { reducer as auth } from "./auth";

import { reducer as users } from "./user/list";

import { reducer as products } from "./product/list";
import { reducer as product } from "./product/new";
import { reducer as productShow } from "./product/show";
import { reducer as productEdit } from "./product/edit";
import { reducer as productType } from "./product/type";
import { reducer as productUnit } from "./product/unit";
import { reducer as productDelete } from "./product/delete";

import { reducer as responsible } from "./responsible/new";
import { reducer as responsibles } from "./responsible/list";
import { reducer as responsibleEdit } from "./responsible/edit";
import { reducer as responsibleShow } from "./responsible/show";
import { reducer as responsibleDelete } from "./responsible/delete";

import { reducer as classification } from "./classification/new";
import { reducer as classifications } from "./classification/list";
import { reducer as classificationShow } from "./classification/show";
import { reducer as classificationEdit } from "./classification/edit";
import { reducer as classificationDelete } from "./classification/delete";

import { reducer as iniciative } from "./initiative/new";
import { reducer as iniciatives } from "./initiative/list";
import { reducer as iniciativeShow } from "./initiative/show";
import { reducer as iniciativeEdit } from "./initiative/edit";
import { reducer as iniciativeDelete } from "./initiative/delete";

import { reducer as quality } from "./quality/new";
import { reducer as qualities } from "./quality/list";
import { reducer as qualityShow } from "./quality/show";
import { reducer as qualityEdit } from "./quality/edit";
import { reducer as qualityDelete } from "./quality/delete";

import { reducer as segment } from "./segment/new";
import { reducer as segments } from "./segment/list";
import { reducer as segmentEdit } from "./segment/edit";
import { reducer as segmentShow } from "./segment/show";
import { reducer as segmentDelete } from "./segment/delete";

// import { reducer as productBranchPlant } from "./productBranchPlant/new";
import { reducer as productBranchPlants } from "./productBranchPlant/list";
import { reducer as productBranchPlantEdit } from "./productBranchPlant/edit";
import { reducer as productBranchPlantShow } from "./productBranchPlant/show";
// import { reducer as productBranchPlantDelete } from "./productBranchPlant/delete";

import { reducer as financialImports } from "./financialImport/list";

import { reducer as importInventory } from "./importInventory";
import { reducer as importFinancial } from "./importFinancial";
import { reducer as stdsStatus } from "./std/list";
import { reducer as slas } from "./sla/list";
import { reducer as productBranchAction } from "./productBranchAction";
import { reducer as language } from "./language";

const reducers = combineReducers({
  auth,
  users,
  products,
  product,
  productShow,
  productEdit,
  productType,
  productDelete,
  productUnit,
  responsible,
  responsibles,
  responsibleShow,
  responsibleEdit,
  responsibleDelete,
  classification,
  classifications,
  classificationShow,
  classificationEdit,
  classificationDelete,
  iniciative,
  iniciatives,
  iniciativeShow,
  iniciativeEdit,
  iniciativeDelete,
  quality,
  qualities,
  qualityShow,
  qualityEdit,
  qualityDelete,
  segment,
  segments,
  segmentEdit,
  segmentShow,
  segmentDelete,
  importInventory,
  importFinancial,
  productBranchPlants,
  productBranchPlantShow,
  productBranchPlantEdit,
  stdsStatus,
  slas,
  productBranchAction,
  language,
  financialImports,
});

export default reducers;
