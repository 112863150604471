import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import QualityDeleteActions from "../../ducks/quality/delete";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* qualityDelete(action) {
  try {
    const { id } = action;
    const url = `quality/${id}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.delete, url, makeOptions(authData));

    yield put(QualityDeleteActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Qualidade da Análise deletada!", "quality.delete", language);
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(QualityDeleteActions.failure(err.response.data.error.message));
  }
}
