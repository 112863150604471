import React from "react";

import "../../../node_modules/react-vis/dist/style.css";

import { FlexibleXYPlot, XAxis, YAxis, LineSeries } from "react-vis";

// import { Container } from './styles';

function ChartLine() {
  return (
    <FlexibleXYPlot>
      <XAxis
        style={{
          strokeWidth: "0",
        }}
      />
      <YAxis
        style={{
          strokeWidth: "0",
        }}
      />
      <LineSeries
        color="#1CAEC1"
        style={{
          fill: "none",
          strokeWidth: "2px",
        }}
        // markStyle={{ stroke: "none" }}
        // curve={"curveMonotoneX"}
        data={[
          { x: 1, y: 1500 },
          { x: 2, y: 1800 },
          { x: 3, y: 1700 },
          { x: 4, y: 3200 },
          { x: 5, y: 2000 },
          { x: 6, y: 2100 },
          { x: 7, y: 2200 },
        ]}
      />
    </FlexibleXYPlot>
  );
}

export default ChartLine;
