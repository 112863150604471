import React, { useState, useEffect, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormatMessage } from "react-intl-hooks";
import {
  PackageIcon,
  LeftArrowAlt,
  Search,
} from "@styled-icons/boxicons-regular";

import ProductsActions from "~/store/ducks/product/list";

import Header from "~/components/Header";
import Paginator from "~/components/Paginator";
import Loading from "~/components/Loading";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  List,
  Item,
  FilterList,
} from "./styles";
import { ButtonMini } from "~/styles/components";

function Products() {
  const text = useFormatMessage();
  const { data, loading } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(
    (data && data.pagination?.page) || 1
  );

  const fectProducts = useCallback(() => {
    dispatch(ProductsActions.request());
  }, [dispatch]);

  useEffect(() => {
    fectProducts();
  }, [fectProducts]);

  const results = !search
    ? data && data.data
    : data.data.filter(
        (e) =>
          e.name.toLowerCase().includes(search.toLocaleLowerCase()) ||
          e.code.toLowerCase().includes(search.toLocaleLowerCase())
      );

  console.log(data && data.data);

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <PackageIcon size={24} />
          <Text id="produtos" dm="Produtos" />
        </Title>
        <FilterList>
          <form action="">
            <Search size={24} />
            <input
              type="text"
              placeholder={text({
                id: "produtos.buscar",
                defaultMessage: "Buscar produto...",
              })}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </FilterList>
        <span>
          <ButtonMini
            btStyle="primary"
            onClick={() => history.push("/product/new")}>
            <Text id="produtos.novo" dm="Novo produto" />
          </ButtonMini>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>

      <Content>
        <List>
          <header>
            <p>
              <Text id="produtos.descr" dm="Descrição" />
            </p>
            <p>
              <Text id="produtos.medida" dm="UN. Medida" />
            </p>
            <p>
              <Text id="produtos.sap" dm="Código SAP" />
            </p>
            <p>
              <Text id="produtos.tipo" dm="Tipo" />
            </p>
            <p>
              <Text id="produtos.qtdkg" dm="QTD. KG" />
            </p>
            <p>
              <Text id="produtos.qtduni" dm="QTD. Unidade" />
            </p>
            <p>
              <Text id="produtos.qtdlas" dm="QTD. Lastro" />
            </p>
            <p>
              <Text id="produtos.qtdemp" dm="QTD. Empilhado" />
            </p>
          </header>

          {loading ? (
            <Loading />
          ) : (
            <>
              {data &&
                results.map((product) => (
                  <Link to={`/product/${product.id}`} key={product.id}>
                    <Item>
                      <p>{product.name}</p>
                      <p>{product.unit.name}</p>
                      <p>{product.code}</p>
                      <p>{product.type.name}</p>
                      <p>{product.balance}</p>
                      <p>{product.quantity_unit}</p>
                      <p>{product.quantity_ballast}</p>
                      <p>{product.quantity_piled}</p>
                    </Item>
                  </Link>
                ))}
            </>
          )}
        </List>
        {loading ? (
          ""
        ) : (
          <Paginator
            pagination={data.pagination}
            onPageChange={setCurrentPage}
          />
        )}
      </Content>
    </Container>
  );
}

export default Products;
