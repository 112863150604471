import styled from "styled-components";
import * as Co from "@styled-icons/boxicons-regular/Comment";
import * as Cos from "@styled-icons/boxicons-solid/Comment";

export const Container = styled.div`
  background: #fff;
  border-radius: 6px;
  padding: 16px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s ease-in;
`;

export const IconComent = styled(Co.Comment)`
  color: #616788;
`;

export const IconComentSolid = styled(Cos.Comment)`
  color: #616788;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  span {
    width: 100%;
    display: flex;
  }
  p {
    margin-right: 20px;
    font-family: ${({ theme }) => theme.fonts.titleSemiBold};
    color: ${({ theme }) => theme.colors.blueDark};
    font-size: 12px;
  }
`;
export const Description = styled.div`
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 20px 0;
  strong {
    margin-bottom: 10px;
    display: block;
    font-family: ${({ theme }) => theme.fonts.titleBold};
    color: ${({ theme }) => theme.colors.blueDark};
    font-size: 12px;
    text-transform: uppercase;
  }
  span {
    display: block;
    padding: 24px;
    background: #f5f5f5;
    border-radius: 6px;
    p {
      font-family: ${({ theme }) => theme.fonts.defautl};
      font-size: 14px;
    }
  }
`;
