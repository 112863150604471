import React, { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UsersActions from "~/store/ducks/user/list";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";

import Header from "~/components/Header";
import Loading from "~/components/Loading";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  ListInit,
  ItemInit,
} from "../styles";
import { ButtonMini } from "~/styles/components";

function Users() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state.users);

  const fectUsers = useCallback(() => {
    dispatch(UsersActions.request());
  }, [dispatch]);

  useEffect(() => {
    fectUsers();
  }, [fectUsers]);

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="user.title" dm="Usuários" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      {/* <button onClick={() => setLanguage("en")}>aquiii</button> */}
      <Content>
        <ListInit>
          <header>
            <p>
              <Text id="user.codigo" dm="Código" />
            </p>
            <p>
              <Text id="user.descricao" dm="Descrição" />
            </p>
            <p>
              <Text id="user.type" dm="Tipo" />
            </p>
          </header>
          {loading ? (
            <Loading />
          ) : (
            <>
              {data.data.map((item) => (
                <ItemInit key={item.id}>
                  <p>{item.id}</p>
                  <p>{item.name}</p>
                  <p>{(item.usuario.user_types && item.usuario.user_types[0]) ? item.usuario.user_types[0].type.name : ''}</p>
                </ItemInit>
              ))}
            </>
          )}
        </ListInit>
      </Content>
    </Container>
  );
}

export default Users;
