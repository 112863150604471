import React, { useState } from "react";
import { useSelector } from "react-redux";
import Text from "~/components/Text";

import {
  Container,
  Header,
  Description,
  IconComent,
  IconComentSolid,
} from "./styles";

function Accordion({data}) {
  const { data: language } = useSelector((state) => state.language);
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <Header onClick={() => setOpen(!open)}>
        <span>
          <p>
            <Text id="inventory.std" dm="STD Status"/>
            : {data && (
                language === 'en'?
                data.std_status.name :
                data.std_status[`name_${language}`]
              )}
          </p>
          <p>
            <Text id="inventory.quality" dm="Análise de Qualidade" />
            : {data && data.quality.name}
          </p>
        </span>
        <p>JOSIELI MACHADO</p>
        {!open ? <IconComent size="20" /> : <IconComentSolid size="20" />}
      </Header>
      {open && (
        <>
        <Description>
          <strong>
            <Text id="inventory.status_details" dm="Status de detalhes" />
          </strong>
          <span>
            <p>
              {data && data.status_details}
            </p>
          </span>
        </Description>
        <Description>
          <strong>
            <Text id="inventory.observation" dm="Observação do Planejador" />
          </strong>
          <span>
            <p>
              {data && data.observation}
            </p>
          </span>
      </Description>
      </>
      )}
    </Container>
  );
}

export default Accordion;
