import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import Login from "../pages/Login";
import Inventory from "../pages/Inventory";
import InventoryDetails from "../pages/Inventory/Details";
//Produto
import Products from "../pages/Products";
import ProductNew from "../pages/Products/New";
import ProductDetails from "../pages/Products/Details";
//Configuração
import Classification from "../pages/Config/Classification";
import ClassificationNew from "../pages/Config/Classification/new";
import ClassificationDetails from "../pages/Config/Classification/details";
import Responsible from "../pages/Config/Responsible";
import ResponsibleNew from "../pages/Config/Responsible/new";
import ResponsibleDetails from "../pages/Config/Responsible/details";
import Segment from "../pages/Config/Segment";
import SegmentNew from "../pages/Config/Segment/new";
import SegmentDetails from "../pages/Config/Segment/details";
import Iniciative from "../pages/Config/Initiative";
import IniciativeNew from "../pages/Config/Initiative/new";
import IniciativeDetails from "../pages/Config/Initiative/details";
import Quality from "../pages/Config/Quality";
import QualityNew from "../pages/Config/Quality/new";
import QualityDetails from "../pages/Config/Quality/details";
import Users from "../pages/Config/Users";
import Import from "../pages/Config/Import";
import FinancialImport from "../pages/Config/FinancialImport";


export default function Routes({ editlanguage }) {
  const { logado } = useSelector((state) => state.auth);
  return (
    <BrowserRouter langtest={editlanguage}>
      <Switch>
        <Route
          path="/"
          exact
          render={() =>
            logado ? (
              <Redirect to={{ pathname: "/inventory" }} />
            ) : (
              <Redirect to={{ pathname: "/login" }} />
            )
          }
        />
        <Route
          path="/Login"
          exact
          render={() => logado && <Redirect to={{ pathname: "/inventory" }} />}
          component={!logado && Login}
        />
        <PrivateRoute
          authed={logado}
          path="/inventory"
          exact
          component={Inventory}
        />
        <PrivateRoute
          authed={logado}
          path="/product-details/:id"
          exact
          component={InventoryDetails}
        />
        <PrivateRoute
          authed={logado}
          path="/products"
          exact
          component={Products}
        />
        <PrivateRoute
          authed={logado}
          path="/product/new"
          exact
          component={ProductNew}
        />
        <PrivateRoute
          authed={logado}
          path="/product/:id"
          component={ProductDetails}
        />
        <PrivateRoute
          authed={logado}
          path="/config/type"
          exact
          component={Classification}
        />
        <PrivateRoute
          authed={logado}
          path="/config/type/new"
          exact
          component={ClassificationNew}
        />
        <PrivateRoute
          authed={logado}
          path="/config/type/:id"
          component={ClassificationDetails}
        />
        <PrivateRoute
          authed={logado}
          path="/config/responsible"
          exact
          component={Responsible}
        />
        <PrivateRoute
          authed={logado}
          path="/config/responsible/new"
          exact
          component={ResponsibleNew}
        />
        <PrivateRoute
          authed={logado}
          path="/config/responsible/:id"
          component={ResponsibleDetails}
        />
        <PrivateRoute
          authed={logado}
          path="/config/segment"
          exact
          component={Segment}
        />
        <PrivateRoute
          authed={logado}
          path="/config/segment/new"
          exact
          component={SegmentNew}
        />
        <PrivateRoute
          authed={logado}
          path="/config/segment/:id"
          component={SegmentDetails}
        />
        <PrivateRoute
          authed={logado}
          path="/config/initiative"
          exact
          component={Iniciative}
        />
        <PrivateRoute
          authed={logado}
          path="/config/initiative/new"
          exact
          component={IniciativeNew}
        />
        <PrivateRoute
          authed={logado}
          path="/config/initiative/:id"
          component={IniciativeDetails}
        />
        <PrivateRoute
          authed={logado}
          path="/config/quality"
          exact
          component={Quality}
        />
        <PrivateRoute
          authed={logado}
          path="/config/quality/new"
          exact
          component={QualityNew}
        />
        <PrivateRoute
          authed={logado}
          path="/config/quality/:id"
          component={QualityDetails}
        />
        <PrivateRoute
          authed={logado}
          path="/config/users"
          exact
          component={Users}
        />
        <PrivateRoute
          authed={logado}
          path="/config/import"
          exact
          component={Import}
        />
        <PrivateRoute
          authed={logado}
          path="/config/financial-import"
          exact
          component={FinancialImport}
        />
      </Switch>
    </BrowserRouter>
  );
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
}
