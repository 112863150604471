import React, { useRef } from "react";

import * as Yup from "yup";
import { Form } from "@unform/web";

import { useDispatch } from "react-redux";
import AuthActions from "~/store/ducks/auth";

import {
  Container,
  Content,
  ImageBackground,
  Logo,
  Wrapper,
  Title,
  Subtitle,
  InputsWrapper,
  Ghost,
} from "./styles";

import { Input } from "~/components/Form";
import { Button } from "~/styles/components";

function Login() {
  const formLogin = useRef();
  const dispatch = useDispatch();
  // const { loading } = useSelector((state) => state.auth);

  async function handleSubmit(data) {
    try {
      formLogin.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email("E-mail inválido")
          .required("Digite seu e-mail de acesso"),
        password: Yup.string().required("A senha é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(AuthActions.request(data));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Content>
        <Logo />
        <Wrapper>
          <Title>Inventory management</Title>
          <Subtitle>Log in to your account</Subtitle>
          <Form ref={formLogin} onSubmit={handleSubmit}>
            <InputsWrapper>
              <Input name="email" placeholder="Email:" className="first" />
              <Input
                name="password"
                placeholder="Senha:"
                type="password"
                className="last"
              />
            </InputsWrapper>
            <Button type="submit" btStyle="primary">
              Entrar
            </Button>
          </Form>
        </Wrapper>
        <Ghost />
      </Content>
      <ImageBackground />
    </Container>
  );
}

export default Login;
