import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 24px;
  form textarea {
    height: 100px;
    resize: none;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #007eff;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #007eff;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgb(0 126 255 / 0.1);
  }
`;
export const Row = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(${(props) => props.templatecolumns}, 1fr);
  margin-bottom: 20px;
`;
export const WrapBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  button {
    margin-left: 20px;
  }
`;

export const Title = styled.p`
  font-size: 20px;
  font-family: ${({ theme }) => theme.fonts.titleBold};
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 20px;
`;
export const ListNpi = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 40px;
`;
export const Item = styled.li`
  padding: 6px 16px;
  background: ${({ theme }) => theme.colors.darkRgba8};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.titleBold};
  color: ${({ theme }) => theme.colors.blueDark};
  border-radius: 4px;
  gap: 10px;
`;
export const BoxCLassif = styled.div`
  p {
    font-family: ${({ theme }) => theme.fonts.titleBold};
    font-size: 16px;
    color: #2b2b2b;
    margin-bottom: 8px;
  }
`;

export const BoxNpi = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  gap: 20px;
  border-bottom: solid 1px #f5f5f5;
  margin-bottom: 20px;
`;
