import React from "react";

import { Container, Wrapper, Name, Percent, BgBar, Bar, Value } from "./styles";

function CardStatus() {
  return (
    <Container>
      <Wrapper>
        <Name>Pending Analisys</Name>
        <Percent>40%</Percent>
      </Wrapper>
      <BgBar>
        <Bar value={50} />
      </BgBar>
      <Wrapper>
        <Value>200</Value>
        <Value>500</Value>
      </Wrapper>
    </Container>
  );
}

export default CardStatus;
