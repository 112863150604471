import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ProductBranchPlantEditActions from "~/store/ducks/productBranchPlant/edit";
import IniciativesActions from "~/store/ducks/initiative/list";

import { Form } from "@unform/web";
import { SelectTest } from "~/components/Form";
import Modal from "~/components/Modal";
import { Button } from "~/styles/components";
import Text from "~/components/Text";

import * as S from "./styles";

function ModalInitiative({ open, handleClose, listChecked, onInitiativeSubmit}) {
  const dispatch = useDispatch();
  const formInitiative = useRef();
  const close = () => {
    handleClose();
  };

  const [checked, setChecked] = React.useState(false);

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const { data: initiativeList } = useSelector((state) => state.iniciatives);

  useEffect(() => {
    dispatch(IniciativesActions.request());
  }, []);

  async function handleInitiativeSubmit(values) {
    try {
      formInitiative.current.setErrors({});
      const schema = Yup.object().shape({
        initiative: Yup.object().typeError("Selecione uma iniciativa"),
      });
      await schema.validate(values, {
        abortEarly: false,
      });

      const data = {
        initiative_id: values.initiative.id,
      };

      await listChecked.map(async item => {
        const id = item.id
        await dispatch(ProductBranchPlantEditActions.request(id, data));
      });

      onInitiativeSubmit();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formInitiative.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal open={open} close={close}>
      <S.Container>
        <S.Title>
          <Text id="modal.npi" dm="NPIs Selecionados" />
        </S.Title>
        <S.ListNpi>
          {listChecked.map((item) => (
            <S.Item key={item.id}>
              <Text id="inventory.batch" dm="Lote" />: {item.batch}
            </S.Item>
          ))}
        </S.ListNpi>
        <Form
          ref={formInitiative}
          onSubmit={handleInitiativeSubmit}>
          <S.Row templatecolumns="1">
            <SelectTest
              label={<Text id="initiative.title" dm="Iniciativa" />}
              name="initiative"
              placeholder="Selecione uma Iniciativa"
              options={initiativeList && initiativeList.data}
            />
          </S.Row>
          <S.WrapBtns>
            <Button type="submit" btStyle="primary">
              <Text id="salvar" dm="Salvar" />
            </Button>
          </S.WrapBtns>
        </Form>
      </S.Container>
    </Modal>
  );
}

export default ModalInitiative;
