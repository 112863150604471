import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ProductActions from "../../ducks/product/new";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* createProduct(action) {
  try {
    const { post } = action;
    const url = `product`;
    const authData = yield select(getAuthData);
    console.log(authData);
    const { data } = yield call(api.post, url, post, makeOptions(authData));

    yield put(ProductActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Produto cadastrado!", "product.new", language);
    console.log(data);
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(ProductActions.failure(err.response.data.error.message));
  }
}
