import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { useDispatch } from "react-redux";
import SegmentActions from "~/store/ducks/segment/new";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";

import Header from "~/components/Header";
import { Input } from "~/components/Form";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../styles";
import { ButtonMini, Button } from "~/styles/components";

function Segment() {
  const formLogin = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  async function handleSubmit(data) {
    try {
      formLogin.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required("Digite o seguimento"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      console.log(data);
      await dispatch(SegmentActions.request(data));
      history.push("/config/segment");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="segmento.title" dm="Segmento" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="informacoes" dm="Informações" />
        </SubTitle>
        <Form ref={formLogin} onSubmit={handleSubmit}>
          <WrapForm>
            <Row templatecolumns="1">
              <Input
                label={<Text id="segmento.title" dm="Segmento" />}
                name="name"
              />
            </Row>
          </WrapForm>
          <WrapBtns>
            <Button type="reset" btStyle="secondary">
              <Text id="cancelar" dm="Cancelar" />
            </Button>
            <Button type="submit" btStyle="primary">
              <Text id="cadastrar" dm="Cadastrar" />
            </Button>
          </WrapBtns>
        </Form>
      </Content>
    </Container>
  );
}

export default Segment;
