import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PackageIcon, FilterAlt } from "@styled-icons/boxicons-regular";
import { Class } from "@styled-icons/material/Class";
import { Update } from "@styled-icons/material/Update";
import ProductBranchPlantsActions from "~/store/ducks/productBranchPlant/list";

import {
  Container,
  Filters,
  Title,
  WrapperButtons,
  ButtonFilter,
  Content,
  ButtonModal,
} from "./styles";

import { ButtonMini } from "~/styles/components";
import Header from "~/components/Header";
import Loading from "~/components/Loading";
import { SliderStatus } from "~/components/Status";
import { ListNPI, Filter, Modal, ModalInit } from "~/components/NPI";
import Text from "~/components/Text";

function Inventory() {
  const dispatch = useDispatch();
  const { data, pagination, loading } = useSelector(
    (state) => state.productBranchPlants
  );
  const [currentPage, setCurrentPage] = useState(
    (pagination && pagination?.page) || 1
  );

  const [filterPage, setFilterPage] = useState();
  const [listChecked, setListChecked] = useState([]);
  const [filter, setFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [openInit, setOpenInit] = useState(false);

  function onfilter(data) {
    setFilter(data);
  }

  const handleClose = () => {
    setOpen(false);
    setOpenInit(false);
  };

  const verifyCheck = async (checked, productBranchPlant) => {
    if (checked) {
      await setListChecked([...listChecked, productBranchPlant]);
    } else {
      let newListChecked = listChecked.filter(item => item.id !== productBranchPlant.id);
      await setListChecked(newListChecked);
    }
  };

  const fectSegments = useCallback((page, filters) => {
    const filter = {
      paginate: page,
      ...filters,
    };

    dispatch(ProductBranchPlantsActions.request(filter));
  }, [dispatch]);

  useEffect(() => {
    fectSegments(currentPage, filterPage);
  }, [fectSegments, currentPage, filterPage]);

  return (
    <Container>
      <ButtonModal
        className={listChecked && listChecked.length > 0 && "active"}>
        <button onClick={() => setOpen(true)}>
          <Update size={30} color="#fff" />
          <Text id="inventory.attstatus" dm="Atualizar Status" />
        </button>
        <button onClick={() => setOpenInit(true)}>
          <Class size={30} color="#fff" />
          <Text id="inventory.classificar" dm="Atribuir Iniciativa" />
        </button>
      </ButtonModal>

      <Header />
      <Modal
        open={open}
        handleClose={handleClose}
        listChecked={listChecked}
        onActionSubmit={() => {
          setListChecked([]);
          handleClose();
          fectSegments();
        }}
      />
      <ModalInit
        open={openInit}
        handleClose={handleClose}
        listChecked={listChecked}
        onInitiativeSubmit={() => {
          setListChecked([]);
          handleClose();
          fectSegments();
        }}
      />
      <Filters>
        <Title>
          <PackageIcon size={24} />
          <Text id="inventory.title" dm="Inventory" />
        </Title>
        <WrapperButtons>
          <ButtonMini btStyle="primary">
            <Text id="inventory.planner" dm="Planejador" />
          </ButtonMini>
          <ButtonMini btStyle="secondary">NPI</ButtonMini>
          <ButtonFilter onClick={() => setFilter(!filter)}>
            <FilterAlt size={24} color={filter ? "#1976D2" : ""} />
          </ButtonFilter>
        </WrapperButtons>
      </Filters>
      <Filter
        active={filter}
        onfilter={onfilter}
        filterPage={setFilterPage}
        onPageChange={setCurrentPage}
      />
      <Content>
        <SliderStatus />
        {loading ? (
          <Loading />
        ) : (
          <ListNPI
            verifyCheck={verifyCheck}
            data={data}
            onPageChange={setCurrentPage}
            pagination={pagination}
          />
        )}
      </Content>
    </Container>
  );
}

export default Inventory;
